import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import Vue from "vue";
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'first_name',    label: 'الاسم', sortable: true },
    { key: 'father_name',   label: 'اسم الاب ', sortable: true },
    { key: 'last_name',   label: ' الكنية ', sortable: true },
    { key: 'mother_name',    label: "اسم الام",sortable: true },
    { key: 'last_name',    label: "النسبة ",sortable: true },
    {
      key: 'applied_position.name',
      label: "المنصب الوظيفي",
      formatter: title,
      sortable: true,
    },
    { key: "user_id", label: "الموظف",formatter: title,},
    { key: 'address',   label: "العنوان", sortable: true },
    { key: 'phone',    label: "رقم الهاتف",sortable: true },
    { key: 'email',    label: 'الايميل',sortable: true },
 
    { key: "actions", label: "الخيارات" },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const positionOptions =ref([])
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })
  const DeleteItem=(id)=>{
    Vue.swal({
      icon: "warning",
      title: "هل انت متاكد؟؟",
      confirmButtonText: "YES",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
      },
    }).then((result) => {
      if (result.value) {
        store.dispatch('app-candidates/DeleteCandidates', id).then((response) => {
          refetchData()
          Vue.swal({
            title: "تم الحذف بنجاح ",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      }
    });
  }
  store.dispatch('app-candidates/getPosition') .then(response => {
    //console.log('positionOptions',response.data.data)
    positionOptions.value=response.data.data
  })
  const fetchCandidates = (ctx, callback) => {
    store
      .dispatch('app-candidates/fetchCandidates', {
        name: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
     
        applied_position: roleFilter.value,
      
      })
      .then(response => {
        //console.log('api/v1/candidates',response)
        const { data, total } = response.data

        callback(data)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    DeleteItem,
    fetchCandidates,
    positionOptions,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
